import React from "react";
import style from "./Home.module.css";
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import { QtdContext } from "../../../context/qtdContext";
import Button from '../../../components/Button/Button'
import 'react-tabs/style/react-tabs.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { FcCancel, FcEngineering } from "react-icons/fc";
import $api from '../../../http/index.ts'
import './react-confirm-alert.css'; // Import css

import { useEffect, useState, useContext } from "react";
import LinksMenu from "../../../components/Header/LinksMenu";
// import AddProductModal from "../../../components/Modals/AddProductModal";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import AddEmployeesModal from "../../../components/Modals/Emloyees/AddEmployeesModal.js";
import EditEmployeesModal from "../../../components/Modals/Emloyees/EditEmployeesModal.js";

const Smenas = () => {
  const { checkAuth, editEmployeesModal, setEditEmployeesModal, smenas, admin, admins, setAdmins, addEmployeesModal, setAddEmployeesModal, setDashboarPage, shops } = useContext(QtdContext);
  const [loading, seloading] = useState(false)
  const [id, setId] = useState('')

  if (!(admin.role == 'AllRights' || admin.role == 'Администратор')) {
    setDashboarPage('Home')
    // setAdmin({ auth: false});
    return <Navigate to="/" replace />

  } else {

    // console.log(admin.role != 'GOD' && admin.role != 'Администратор')
    // useEffect(()=>{})
    const users = admins.filter(pr => pr.role != 'AllRights')
    const sorted = smenas.sort((a, b) => a.id < b.id ? 1 : -1);
    const addProduct = () => {
      setAddEmployeesModal(true)
    }

    // useEffect(())

    const OpnaEditModal = (id) => {
      setId(id)
      setEditEmployeesModal(id)
    }
    const deleteIt = async (id) => {
      try {
        const refresh = await checkAuth()
        if (refresh) {
          const deleteProd = await $api.get(`${BACKURL}/api/admins/delete/${id}`)
          setAdmins(admins.filter(item => item.id != id))
        }


      } catch (e) {
        console.log(e)
      }
    }

    const submit = async (func, id) => {
      confirmAlert({
        title: 'Удалить сотрудника',
        message: 'Вы уверены, что хотите удалить сотрудника из системы?',
        buttons: [
          {
            label: 'Да!',
            onClick: async () => await func(id)
          },
          {
            label: 'Отмена!',
            //   onClick: () => alert('Click No')
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
      });
    };
    return (<>

      <div className={style.sclad_container}>
        {/* <Button text='Создать Пользователя' background='orange' onClick={addProduct} /> */}

        <div className={style.table2}>
          <table className={style.table}>
            <thead className={style.tableth}>

              <tr>
                <td className={style.tabletd}>№</td>
                <td className={style.tabletd}>Дата Открытия</td>
                <td className={style.tabletd}>Дата Закрытия</td>
                <td className={style.tabletd}>Точка</td>
                <td className={style.tabletd}>Выручка</td>
                <td className={style.tabletd}>Ответственный</td>
                <td className={style.tabletd}>Статус</td>
              </tr>
            </thead>
            <tbody>
              {sorted.map(employee => {

                const date = new Date(employee.createdAt);
                const dateC = new Date(employee.updatedAt);
                const formattedDateC = [date.getMonth() + 1,
                dateC.getDate(),
                dateC.getFullYear()].join('/') + ' ' +
                  [dateC.getHours(),
                  dateC.getMinutes()].join(':');
                const formattedDate = [date.getMonth() + 1,
                date.getDate(),
                date.getFullYear()].join('/') + ' ' +
                  [date.getHours(),
                  date.getMinutes()].join(':');

                const color = employee.open ? 'green' : 'red'
                // Форматирование времени в формате HH:mm

                const sh = shops.find(pr => pr.id == employee.shop_id)
                const adm = admins.find(pr => pr.id == employee.admin_id)
                let status = employee.open ? 'Открыта' : 'Закрыта'
                return (
                  <tr>
                    <td className={style.tabletd}>{employee.id}</td>
                    <td className={style.tabletd}>{formattedDate}</td>
                    <td className={style.tabletd}>{formattedDateC}</td>
                    <td className={style.tabletd}>{sh.name}</td>
                    <td className={style.tabletd}>{employee.money}</td>
                    <td className={style.tabletd}>{adm.fullname}</td>
                    <td className={style.tabletd} style={{ background: color }}>{status}</td>

                  </tr>
                )
              }
              )}



            </tbody>
          </table>
        </div>

      </div>

      {editEmployeesModal && (
        <EditEmployeesModal
          id={id}

        />

      )}
      {addEmployeesModal && (
        <AddEmployeesModal

        />

      )}

      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}


    </>
    );
  }


};

export default Smenas;
