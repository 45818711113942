import React, { useContext, useEffect, useState } from "react";
import style from "../Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../Button/Button.js";
import { QtdContext } from "../../../context/qtdContext.js";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import $api from '../../../http/index.ts'
import { BACKURL } from '../../../conf.js'
import Loading from 'react-fullscreen-loading';
import DatePicker from "react-datepicker";
import { confirmAlert } from 'react-confirm-alert'; // Import
import './react-confirm-alert.css'; // Import css

import "react-datepicker/dist/react-datepicker.css";
const EditShopKassasModal = ({ id }) => {

  const { editShopKassasModal, setEditShopKassasModal, shopKassas, setShopKassas, editShopIncasationsModal, setEditShopIncasationsModal, incosations, setIncosations, Notify, editEmployeesModal, setEditEmployeesModal, addEmployeesModal, setAddEmployeesModal, admins, setAdmins, admin } = useContext(QtdContext);
  // const employee = admins.find(pr => pr.id === id)
  // console.log(incosations.find(pr => pr.id == id))
  const [newValue, setNeValue] = useState(shopKassas.find(pr => pr.id == id))
  const [loading, seloading] = useState(false)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container1246422" && setEditShopKassasModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };

  const [err, setErr] = useState({ money: false })

  const editMoney = async () => {
    try {
      // e.preventDefault()
      // console.log(newValue)
      let t = {
        money: false,

      }

      if (!newValue.money) {
        t.money = `Введите сумму наличных в кассе!`

      }
      if (!(Number(newValue.money) > 0)) {
        t.money = `Сумма наличных в кассе должна быть положительным числом!`

      }

      if (!newValue.razmen) {
        t.razmen = `Введите сумму наличных в размене!`

      }
      if (!(Number(newValue.razmen) > 0)) {
        t.razmen = `Сумма наличных в размене должна быть положительным числом!`

      }



      setErr(t)

      if (
        !t.money &&
        !t.razmen &&

        newValue.razmen &&
        newValue.money &&
        newValue.id


      ) {
        seloading(true)
        // console.log(newValue)
        const newProd = await $api.post(`${BACKURL}/api/shopkassas/update/${id}`,
          newValue
        )
        // console.log()
        setShopKassas(shopKassas.map(pr => pr.id == newProd.data.id ? newProd.data : pr))

        seloading(false)
        Notify.addNotification({
          title: "Готово!",
          message: "Касса изменена!",
          type: "info",
          insert: "bottom",
          container: "bottom-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
            onScreen: true
          }
        });
        // localStorage.setItem("petNew", JSON.stringify(newPosts));
        setEditShopKassasModal(false)
        // alert('Добавлено!')
      }

    } catch (e) {
      seloading(false)
      // console.log(e)
      Notify.addNotification({
        title: "Ошибка!",
        message: "Произошла непредвиденная ошибка!",
        type: "danger",
        insert: "bottom",
        container: "bottom-center",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      })
    }


  }

  const submit = async (func, title, message, arg) => {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: 'Да!',
          onClick: async () => await func(arg)
        },
        {
          label: 'Отмена!',
          //   onClick: () => alert('Click No')
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
    });
  };
  // const uniqueSerials = Array.from(new Set(sclad.map(item => item.kategory))); 
  return (
    <>
      {editShopKassasModal && (
        <div onClick={closeModal} id="container1246422" className={style.modal_container}>

          <div className={style.borders}>
            <div className={style.inputtest}>

              <label >Сумма:</label>

              <input type="number" id="pass" name="pass"
                value={newValue.money}
                inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                step="0.01" // шаг ввода для ограничения на два знака после запятой
                min="0" // минимальное значение
                className={style.kassainput}
                onChange={(event) => {
                  setNeValue({ ...newValue, money: event.target.value });
                }} />
              {err.money && (
                <p style={{ color: "red" }}>{err.money}</p>
              )}
            </div>
            <div className={style.inputtest}>

              <label >Размен:</label>

              <input type="number" id="pass" name="pass"
                value={newValue.razmen}
                inputMode="decimal" // для открытия цифровой клавиатуры на мобильных устройствах
                pattern="^\d*\.?\d{0,2}$" // дополнительная валидация
                step="0.01" // шаг ввода для ограничения на два знака после запятой
                min="0" // минимальное значение
                className={style.kassainput}
                onChange={(event) => {
                  setNeValue({ ...newValue, razmen: event.target.value });
                }} />
              {err.razmen && (
                <p style={{ color: "red" }}>{err.razmen}</p>
              )}
            </div>


            <Button
              text="Изменить"
              background="var(--primary-color)"
              color="white"
              // width="100%"
              onClick={(e) => submit(editMoney, 'Изменить?', 'Изменить сумму инкассации?'
              )} />




          </div>

        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default EditShopKassasModal;
